<template>
  <div class="be-discovered">
    <h1 class="title"></h1>

    <div class="gender-selection">
      <label class="radio-label" :class="{ selected: gender === 'female' }">
        <input type="radio" value="female" v-model="gender" /> Female
      </label>
      <label class="radio-label" :class="{ selected: gender === 'male' }">
        <input type="radio" value="male" v-model="gender" /> Male
      </label>
      <label class="radio-label" :class="{ selected: gender === 'other' }">
        <input type="radio" value="other" v-model="gender" /> Other
      </label>
    </div>

    <form @submit.prevent="submitApplication" class="application-form">
      <input type="text" v-model="fullName" placeholder="Full name*" required />
      <input type="date" v-model="birth" placeholder="Birth" required />
      <input type="email" v-model="email" placeholder="Email*" required />
      <input type="height" v-model="email" placeholder="Height" required />
      <input type="bust" v-model="email" placeholder="Bust" required />
      <input type="waist" v-model="email" placeholder="Waist" required />
      <input type="hips" v-model="email" placeholder="Hips" required />
      <div class="phone-section">
        <select v-model="selectedCountry" @change="updatePhoneCode">
          <option v-for="country in countries" :key="country.code" :value="country">
            {{ country.name }} ({{ country.phoneCode }})
          </option>
        </select>
        <input type="tel" v-model="phone" :placeholder="'Phone ' + selectedPhoneCode" />
      </div>

      <input type="text" v-model="instagram" placeholder="Instagram" />

      <button type="submit" :disabled="!validForm">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'BeDiscoveredPage',
  data() {
    return {
      fullName: '',
      birth: '',
      email: '',
      phone: '',
      instagram: '',
     // address: '',
      gender: '',
      height: '',
      bust: '',
      waist: '',
      hips: '',
      selectedCountry: null,
      selectedPhoneCode: '',
      countries: [
        { name: 'Russia', code: 'RU', phoneCode: '+7' },
        { name: 'United States', code: 'US', phoneCode: '+1' },
        { name: 'United Kingdom', code: 'GB', phoneCode: '+44' },
        { name: 'Germany', code: 'DE', phoneCode: '+49' },
        { name: 'France', code: 'FR', phoneCode: '+33' },
        { name: 'Spain', code: 'ES', phoneCode: '+34' },
        { name: 'Italy', code: 'IT', phoneCode: '+39' },
        { name: 'Turkey', code: 'TR', phoneCode: '+90' },
        { name: 'Japan', code: 'JP', phoneCode: '+81' },
      ],
    };
  },
  computed: {
    validForm() {
      return this.fullName && this.email;
    },
  },
  methods: {
    updatePhoneCode() {
      this.selectedPhoneCode = this.selectedCountry.phoneCode;
    },
    submitApplication() {
      const subject = `New Application from ${this.fullName}`;
      const body = `
        Name: ${this.fullName}
        Birth: ${this.birth}
        Email: ${this.email}
        Phone: ${this.selectedPhoneCode} ${this.phone}
        Gender: ${this.gender}
        Instagram: ${this.instagram}
        Height: ${this.height}
        Bust: ${this.bust}
        Waist: ${this.waist}
        Hips: ${this.hips}
        
      `;
      const mailtoLink = `mailto:maria@futurefacemodels.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    },
  },
};

</script>

<style scoped>
.be-discovered {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.title {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #333;
}

.gender-selection {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.radio-label {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 20px;
  transition: background-color 0.3s, transform 0.2s;
  border: 1px solid #ccc;
}

.radio-label.selected {
  background-color: #333;
  color: white;
  transform: scale(1.1);
}

.radio-label input {
  display: none;
}

.application-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.phone-section {
  display: flex;
  gap: 10px;
}

select {
  width: 40%;
  padding: 10px;
  border-radius: 5px;
}

input {
  width: 100%;
  padding: 12px;
  border: none;
  border-bottom: 1px solid #000;
  background: none;
}

button {
  padding: 15px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #444;
}

button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

</style>