import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import BlogPage from '../views/BlogPage.vue';
import ModelsPage from '../views/ModelsPage.vue';
import InstagramPage from '../views/InstagramPage.vue';
import BeDiscoveredPage from '../views/BeDiscoveredPage.vue';
import ContactPage from '../views/ContactPage.vue';
import ModelDetails from '@/components/ModelDetails.vue';
import AdminLogin from '@/views/AdminLogin.vue';
import AdminDashboard from '@/views/AdminDashboard.vue';
import BlogManager from '@/components/BlogManager.vue';
//import { Auth } from "firebase/auth";

const routes = [
  
    { path: '/', name: 'Home', component: HomePage },
    { path: '/blog', name: 'Blog', component: BlogPage },
    { path: '/models', name: 'Models', component: ModelsPage },
    { path: '/models/:id', name: 'ModelDetails', component: ModelDetails },
    { path: '/instagram', name: 'Instagram', component: InstagramPage },
    { path: '/be-discovered', name: 'BeDiscovered', component: BeDiscoveredPage },
    { path: '/contact', name: 'Contact', component: ContactPage },
    { path: '/admin-login', name: 'AdminLogin', component: AdminLogin },
    { path: '/admin-dashboard', name: 'AdminDashboard', component: AdminDashboard, meta: { requiresAuth: true } },
    { path: '/blog-manager', name: 'BlogManager', component: BlogManager, meta: { requiresAuth: true } },
  ];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;