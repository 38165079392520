<template>
  <div class="models-page">
    <h1 class="minimal-heading">Our Models</h1>
    <div class="models-list">
      <ModelCard
        v-for="model in models"
        :key="model.id"
        :model="model"
      />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import ModelCard from '@/components/ModelCard.vue';
import { models } from '@/data/modelsData.js';

export default {
  name: 'ModelsPage',
  components: {
    ModelCard,
  },
  data() {
    return {
      models,
    };
  },
  computed: {
    filteredModels() {
      const searchQuery = this.$route.query.search || "";
      return this.models.filter((model) =>
        model.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.models-page {
  text-align: center;
  padding: 20px;
  font-family: 'Playfair Display', serif;
}

h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.models-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.minimal-heading {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 36px;
  letter-spacing: 1px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}
</style>