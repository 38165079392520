<template>
    <div class="model-details">
      <h1 class="model-name">{{ model.name }}</h1>
      <ul class="model-parameters">
        <li><b>Height:</b> {{ model.height }}</li>
        <li><b>Bust:</b> {{ model.bust }}</li>
        <li><b>Waist:</b> {{ model.waist }}</li>
        <li><b>Hips:</b> {{ model.hips }}</li>
        <li><b>Shoes:</b> {{ model.shoes }}</li>
        <li><b>Hair:</b> {{ model.hair }}</li>
        <li><b>Eyes:</b> {{ model.eyes }}</li>
      </ul>
  
      <div class="photo-gallery">
        <img
          v-for="(photo, index) in model.photos"
          :key="index"
          :src="photo"
          @click="openModal(index)"
          class="gallery-photo"
        />
      </div>
  
      <!-- Модальное окно для просмотра фотографий -->
      <div v-if="showModal" class="modal">
        <button class="close-btn" @click="closeModal">×</button>
        <img :src="model.photos[currentPhotoIndex]" class="modal-photo" />
        <button class="arrow left" @click="prevPhoto">‹</button>
        <button class="arrow right" @click="nextPhoto">›</button>
      </div>
    </div>
  </template>
  
  <script>
  import { models } from "@/data/modelsData.js";
  
  export default {
    data() {
      return {
        model: {},
        showModal: false,
        currentPhotoIndex: 0,
      };
    },
    created() {
      const modelId = this.$route.params.id;
      this.model = models.find((m) => m.id === Number(modelId));
    },
    methods: {
      openModal(index) {
        this.currentPhotoIndex = index;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      nextPhoto() {
        this.currentPhotoIndex =
          (this.currentPhotoIndex + 1) % this.model.photos.length;
      },
      prevPhoto() {
        this.currentPhotoIndex =
          (this.currentPhotoIndex - 1 + this.model.photos.length) %
          this.model.photos.length;
      },
    },
  };
  </script>
  
  <style scoped>
  .model-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100vh;
  }
  
  .model-name {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 300;
    font-size: 36px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .model-parameters {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 400px;
    text-align: left;
  }
  
  .model-parameters li {
    margin-bottom: 10px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #555;
    letter-spacing: 0.5px;
  }
  
  .model-parameters b {
    font-weight: 500;
    color: #333;
  }
  
  .photo-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .gallery-photo {
    width: 300px;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .gallery-photo:hover {
    transform: scale(1.05);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-photo {
    max-width: 90%;
    max-height: 80%;
    border-radius: 10px;
  }
  
  .arrow {
    background: none;
    border: none;
    color: white;
    font-size: 48px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
  }
  
  .left {
    left: 10px;
  }
  
  .right {
    right: 10px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 36px;
    cursor: pointer;
  }
  </style>