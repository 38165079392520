<template>
  <div class="home"> 
    <video autoplay muted loop class="background-video">
      <source src="@/assets/background-video.mp4" type="video/mp4" />
    </video>
    <div class="overlay-content">
      <h1 class="logo-text" @click="toggleMenu">Future Face </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  methods: {
    toggleMenu() {
      this.$emit('toggle-menu'); // Отправляем событие вверх для активации меню
    },
  },
};
</script>

<style scoped>
.home {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo-text {
  font-family: 'Miller Banner Light', Arial, sans-serif;
  font-weight: 300;
  font-size: 48px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgb(3, 3, 3);
  cursor: pointer;
  transition: color 0.3s ease;
}

.logo-text:hover {
  color: rgb(81, 80, 80);
}
</style>