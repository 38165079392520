<template>
  <nav class="navbar">
    <div class="navbar-left">
      <input
        type="text"
        v-if="!isHomePage"
        v-model="searchQuery"
        class="search-input"
        placeholder="Search"
        @input="searchModels"
      /> 
    </div>

    <div class="navbar-right" @click="toggleMenu">
      <i v-if="!menuOpen" class="fas fa-bars"></i>
    </div>

    <transition name="menu-slide">
      <div v-if="menuOpen" class="fullscreen-menu">
        <div class="menu-header">
          <i class="fas fa-times close-icon" @click="closeMenu"></i>
        </div>
        <ul class="menu-list">
          <li @click="navigateTo('/')"><router-link to="/">Main Page</router-link></li>
          <li @click="navigateTo('/blog')"><router-link to="/blog">Blog</router-link></li>
          <li @click="navigateTo('/models')"><router-link to="/models">Models</router-link></li>
          <li><a href="https://www.instagram.com/futurefacemgmt?igsh=azY0ZjY1cTUyMGds" target="_blank">Instagram</a></li>
          <li @click="navigateTo('/be-discovered')"><router-link to="/be-discovered">Be Discovered</router-link></li>
          <li @click="navigateTo('/contact')"><router-link to="/contact">Contacts</router-link></li>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      searchQuery: "", // Track search input
    };
  },
  computed: {
    isHomePage() {
      // Проверяем текущий маршрут
      return this.$route.path === '/';
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    navigateTo(route) {
      this.menuOpen = false;
      this.$router.push(route);
    },
    searchModels() {
      // Navigate to the models page with the search query in the route
      this.$router.push({ path: "/models", query: { search: this.searchQuery } });
    },
  },
};
</script>

<style scoped>
/* Стили для навбара */
.navbar {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px); /* Учитываем отступы */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  z-index: 1000;
}
.search-input {
  width: 100px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid rgb(5, 5, 5);
  padding-left: 10px;
}

/* Размеры и стиль кнопок */
.navbar-left i,
.navbar-right i {
  font-size: 30px;
  color: rgb(3, 3, 3);
  cursor: pointer;
}

/* Исправляем рамки вокруг видео */
.video-container {
  position: fixed; /* Гарантируем, что видео останется на месте */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  z-index: -1; /* Отправляем видео на задний план */
}


.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Убираем рамки, видео полностью заполняет контейнер */
}

/* Полноэкранное меню */
.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(10, 10, 10, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  text-align: center;
}

.menu-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-icon {
  font-size: 30px;
  color: rgb(245, 240, 240);
  cursor: pointer;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fullscreen-menu ul {
  list-style: none;
  padding: 0;
}

.fullscreen-menu li {
  margin: 20px 0;
}

.fullscreen-menu a {
  color: white;
  font-family: 'Miller Banner Light', Arial, sans-serif;
  font-weight: 300;
  font-size: 48px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s;
}

.fullscreen-menu a:hover {
  color: grey;
}

.menu-slide-enter-active, .menu-slide-leave-active {
  transition: transform 0.7s ease;
}

.menu-slide-enter, .menu-slide-leave-to {
  transform: translateY(-100%);
}

</style>