<template>
    <div class="model-card" @click="openModel">
      <img :src="model.mainPhoto" :alt="model.name" />
      <h2 class="model-name">{{ model.name }}</h2>
    </div>
  </template>
  
  <script>
  export default {
    props: ['model'],
    methods: {
      openModel() {
        this.$router.push(`/models/${this.model.id}`);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Стили для карточек моделей */
  .model-card {
    width: 200px;
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 20px;
  }
  
  .model-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Стили для изображения модели */
  .model-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.3s ease;
  }
  
  .model-card img:hover {
    opacity: 0.8;
  }
  
  /* Минималистичный стиль для имени модели */
  .model-name {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-weight: 300;
    font-size: 24px;
    color: #333;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 10px;
    transition: color 0.3s ease;
  }
  
  .model-name:hover {
    color: grey;
  }
  </style>